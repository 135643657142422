<template>
  <th-page-wrapper>
    <th-drawer ref="edit" v-model="isModalOpen" size="500px">
      <receipt-viewer
        v-if="currentRow.id"
        :key="currentRow.id"
        :receipts="currentRow.receipts"
        :receipt-title="currentRow.id"
        :resource-id="currentRow.id"
        class="mx-4 mt-4"
        @close-receipts="closeReceiptViewer"
        @cancel-requested="closeReceiptViewer"
      />
    </th-drawer>

    <th-drawer v-model="isImageViewerOpened" @update:modelValue="toggleOpen">
      <image-viewer
        v-if="currentRow.id"
        :key="currentRow.id"
        :transaction="currentRow.transaction"
        @close-images="toggleOpen(false)"
      />
    </th-drawer>

    <th-datatable
      ref="table"
      expanding-row
      headers-filterable
      no-meta-check
      prune-search-filters
      resource="transactions"
      route-base="/reports/financial_accounting/transactions"
      show-filter
      show-total-count
      sortable
      multiple-select
      transform-fetched-meta-allowed
      :custom-resource="txResource()"
      :document-export="documentExport"
      :force-all-export-columns="true"
      export-document-type="TransactionsExport"
      :header-name-to-export-column-map="headerNameToExportColumnMap"
      :export-filename-prefix="$t('pages.transactions.exports.filename_prefix')"
      :handle-export="handleExport"
      :headers-config="headersConfig"
      :headers-default-hide="headersDefaultHide"
      :headers="headers"
      :locale="locale"
      :resource-limit="100"
      :resource-query="resourceQuery"
      :search-filters="filtersList"
      :show-operations="false"
      :summary-headers="summaryHeaders"
      :transform-fetched-data="transformFetchedData"
      @headers-config="handleHeadersConfig"
      @loading-error="handleLoadingError"
      @search-filter-submit="handleSubmit"
    >
      <template #expanding-row="{ row }">
        <expanding-row
          :row="row"
          @open-current-receipts="handleOpenReceipts"
          @open-current-images="handleOpenImages"
        />
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import qs from 'qs'
import pick from 'just-pick'
import compose from 'just-compose'
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import ExpandingRow from './components/expanding-row'
import ReceiptViewer from '../../../../components/tillhub/receipt-viewer'
import ImageViewer from './components/image-viewer'
import { applyFiltersBeforeRouteEnter, getRangeFor } from '@/utils/date'
import { waitForData } from '@/utils/general'
import { parseQueries, makeQuery } from '@/utils/navigation'
import { getTypesLabels } from '@/views/accounting/payment-options/helpers'
import { useAppConfigStore } from '@/store/app-config'
import { storeToRefs } from 'pinia'
import { useExportsStore } from '@/store/exports'

const selfSustained = ['sale', 'sale_cancel']

export default {
  name: 'ReportsFinancialAccountingTransactions',
  components: {
    ExpandingRow,
    ReceiptViewer,
    ImageViewer
  },
  beforeRouteEnter: (to, _, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    const filters = { type: selfSustained }
    applyFiltersBeforeRouteEnter({
      path: to.path,
      query: to.query,
      next,
      filters
    })
  },
  beforeRouteUpdate(to, _, next) {
    // as UX enhancement we are going to persist some of the queries
    const elegibleObj = pick(safeGet(qs.parse(to.query), 'filter') || {}, [
      'date',
      'register',
      'register_custom_id',
      'register_number',
      'branch_group'
    ])
    this.$emit('route-filter', {
      ...elegibleObj,
      register_custom_id:
        elegibleObj.register_custom_id ||
        elegibleObj.register_number ||
        undefined,
      register_number:
        elegibleObj.register_number ||
        elegibleObj.register_custom_id ||
        undefined,
      register: elegibleObj.register || undefined
    })

    next()
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  setup() {
    const appConfigStore = useAppConfigStore()
    const { featureConfig } = storeToRefs(appConfigStore)
    return { featureConfig }
  },
  metaInfo() {
    return {
      title: this.$t('pages.transactions.title')
    }
  },
  data() {
    return {
      isModalOpen: false,
      isImageViewerOpened: false,
      paymentTypesLabels: getTypesLabels(this),
      currentRow: {
        receipts: [],
        id: null
      },
      paymentOptions: [],
      summaryHeaders: [
        {
          field: 'total_sale_items_count',
          label: this.$t('pages.transactions.all.table.total_sale_items_count'),
          fallback: '-',
          truncate: true,
          formatter: (row) => {
            if (!Number.isFinite(row.total_sale_items_count)) {
              return '-'
            }
            return row.total_sale_items_count
          },
          align: 'right'
        },
        {
          field: 'average_transaction_total',
          label: this.$t(
            'pages.transactions.all.table.absolute_average_transaction_sale_items_total'
          ),
          fallback: '-',
          truncate: true,
          formatter: (row) => {
            if (Number.isFinite(row.average_transaction_total)) {
              return this.$formatCurrency(
                row.average_transaction_total,
                row.currency
              )
            }
            return '-'
          },
          align: 'right'
        },
        {
          field: 'amount_total_gross',
          label: this.$t('pages.transactions.all.table.amount_total_gross'),
          tooltip: this.$t(
            'pages.transactions.all.table.amount_total_gross_tooltip'
          ),
          fallback: '-',
          truncate: true,
          formatter: (row) => {
            if (Number.isFinite(row.total)) {
              return this.$formatCurrency(row.total, row.currency)
            }
            return '-'
          },
          align: 'right'
        }
      ],
      headersDefaultHide: [
        'timezone',
        '_date_local_label',
        '_external_reference_id',
        'customer',
        'customer_custom_id',
        'barcode'
      ],
      headerNameToExportColumnMap: {
        _date_label: 'date',
        timezone: false,
        _date_local_label: false,
        customer: ['customer_firstname', 'customer_lastname'],
        _staff_label: 'cashier_staff_custom_id',
        _type_label: ['type', 'additional_type'],
        barcode: false
      }
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation',
      timeZone: 'Config/getTimeZone',
      locale: 'Config/getLocale',
      localConfiguration: 'Config/getLocalConfiguration',
      defaultDateSelected: 'Config/getDefaultDateSelected'
    }),
    isCrmEnabled() {
      return this.featureConfig?.customers?.crm
    },
    headers() {
      return [
        {
          field: '_date_label',
          label: this.$t('pages.transactions.all.table.date'),
          fallback: '-',
          width: 160,
          truncate: true,
          sortType: 'date'
        },
        {
          field: 'timezone',
          label: this.$t('pages.transactions.all.timezone'),
          fallback: '-',
          width: 160,
          truncate: true
        },
        {
          field: '_date_local_label',
          label: this.$t('pages.transactions.all.table.date_local'),
          fallback: '-',
          width: 160,
          truncate: true,
          sortType: 'date'
        },
        ...(this.isCrmEnabled
          ? [
              {
                field: 'customer',
                label: this.$t('pages.transactions.all.table.customer'),
                fallback: '-',
                width: 160,
                truncate: true,
                formatter: (row) => {
                  if (row.customer_firstname || row.customer_lastname) {
                    return [row.customer_firstname, row.customer_lastname].join(
                      ' '
                    )
                  }
                  return '-'
                }
              },
              {
                field: 'customer_custom_id',
                label: this.$t('pages.transactions.all.table.customer_number'),
                fallback: '-',
                width: 160,
                truncate: true
              }
            ]
          : []),
        {
          field: 'custom_id',
          label: this.$t('pages.transactions.all.table.receipt_number'),
          fallback: '-',
          width: 150,
          truncate: true,
          sortType: 'number'
        },
        {
          field: '_staff_label',
          label: this.$t('pages.transactions.all.table.staff'),
          fallback: '-',
          width: 150,
          truncate: true
        },
        {
          field: 'branch_custom_id',
          label: this.$t('pages.transactions.all.table.branch_custom_id'),
          minWidth: 120,
          fallback: '-',
          truncate: true,
          sortType: 'number'
        },
        {
          field: 'register_custom_id',
          label: this.$t('pages.transactions.all.table.register_custom_id'),
          minWidth: 120,
          fallback: '-',
          truncate: true
        },
        {
          field: 'balance_custom_id',
          label: this.$t('pages.transactions.all.table.balance_number'),
          minWidth: 150,
          fallback: '-',
          truncate: true
        },
        {
          field: 'total',
          label: this.$t('pages.transactions.all.table.amount_total_gross'),
          fallback: '-',
          minWidth: 100,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.total)) {
              return this.$formatCurrency(row.total, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          field: '_type_label',
          label: this.$t('common.headers.type.title'),
          minWidth: 100,
          fallback: '-',
          truncate: true
        },
        {
          field: '_external_reference_id',
          label: this.$t('pages.transactions.all.table.external_reference_id'),
          minWidth: 160,
          fallback: '-',
          truncate: true
        },
        {
          label: this.$t('pages.reports.statistics.products.barcode'),
          field: 'barcode',
          truncate: true,
          align: 'right',
          minWidth: 180
        }
      ]
    },
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter

      return parsedQuery || {}
    },
    headersConfig() {
      return safeGet(
        this.localConfiguration,
        'settings.headerFilters.transactions',
        {}
      )
    },
    filtersList() {
      const transactionTypes = {
        legacy: [
          {
            value: 'sale',
            label: this.$t('common.transactions.types.sale')
          },
          {
            value: 'sale_cancel',
            label: this.$t('common.transactions.types.sale_cancel')
          },
          {
            value: 'expense',
            label: this.$t('common.transactions.types.expense')
          },
          {
            value: 'expense_cancel',
            label: this.$t('common.transactions.types.expense_cancel')
          }
        ],
        proper: [
          {
            value: 'sale',
            label: this.$t('common.transactions.types.sale')
          },
          {
            value: 'sale_cancel',
            label: this.$t('common.transactions.types.sale_cancel')
          },
          {
            value: 'deposit',
            label: this.$t('common.transactions.types.deposit')
          },
          {
            value: 'expense',
            label: this.$t('common.transactions.types.expense')
          },
          {
            value: 'expense_cancel',
            label: this.$t('common.transactions.types.expense_cancel')
          },
          {
            value: 'bank_expense',
            label: this.$t('common.transactions.types.bank_expense')
          }
        ]
      }

      return [
        {
          name: 'custom_id',
          type: 'input',
          label: this.$t('pages.transactions.all.filters.receipt_number.label'),
          placeholder: this.$t('common.inputs.placeholders.custom_id')
        },
        {
          name: 'type',
          type: 'multiselect',
          label: this.$t('common.headers.type.title'),
          placeholder: this.$t('common.inputs.placeholders.select'),
          options: transactionTypes.legacy
        },
        {
          name: 'register_custom_id',
          type: 'select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.transactions.all.table.register'),
          options: this.registers,
          filterable: true
        },
        {
          name: 'balance_custom_id',
          type: 'input',
          label: this.$t('pages.transactions.all.filters.balance_number.label'),
          placeholder: this.$t('common.inputs.placeholders.number')
        },
        {
          name: 'payment_option',
          type: 'multiselect',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.transactions.all.filters.payment_option.label'),
          options: this.mapOptions(this.paymentOptions, 'type'),
          filterable: true
        },
        /*{
          name: 'customer',
          type: 'remote-search-select',
          label: this.$t('pages.transactions.all.table.customer'),
          resource: 'customers',
          overrideInitialFetchHandler: 'get',
          fetchHandler: 'getAll',
          computeName: this.$formatCustomer,
          modifyQuery: (q) => ({
            q,
            limit: 50,
            deleted: false
          })
        },*/
        {
          name: 'staff',
          type: 'remote-search-select',
          label: this.$t('pages.transactions.all.table.staff'),
          resource: 'staff',
          overrideInitialFetchHandler: 'get',
          fetchHandler: 'getAll',
          computeName: (staff) =>
            this.$formatStaff(staff, ['staff_number', 'fullName'], ' - '),
          modifyQuery: (q) => ({
            q,
            limit: 50,
            fields: ['staff_number', 'lastname', 'firstname'],
            deleted: false
          })
        },
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.currentLocation,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        {
          name: 'barcode',
          type: 'input',
          label: this.$t('pages.transactions.all.filters.barcode.label'),
          placeholder: this.$t('common.inputs.placeholders.input')
        },
        {
          name: 'external_reference_id',
          type: 'input',
          label: this.$t(
            'pages.transactions.all.filters.external_refernce_id.label'
          ),
          placeholder: this.$t('common.inputs.placeholders.input')
        },
        {
          name: 'date',
          prop: ['date_start', 'date_end'],
          type: 'daterange',
          closable: false,
          noFutureDates: true,
          label: this.$t('pages.transactions.all.filters.date.label'),
          formatValue: (value) => this.$date.formatDateRange(value),
          default: getRangeFor[this.defaultDateSelected]?.(),
          modifyFilter: (filterObject) => ({
            date_start: filterObject.start,
            date_end: filterObject.end
          })
        },
        {
          name: 'unsigned',
          type: 'switch',
          text: {},
          label: this.$t('pages.transactions.all.filters.unsigned'),
          formatValue: (value) =>
            value === undefined || value === false || value === 'false'
              ? this.$t('common.interactions.buttons.no')
              : this.$t('common.interactions.buttons.yes')
        }
      ]
    },
    resourceQuery() {
      return {
        query: {
          branch: this.currentLocation || undefined,
          limit: 100
        }
      }
    },
    documentExport() {
      return this.$checkPermissions({
        scopes: ['reports_financial_accounting:transactions:export']
      })
    },
    registers() {
      const { registers } = this.resources
      if (!registers) return
      return Array.from(registers || [])
        .filter(([, r]) => r.deleted !== true)
        .filter(
          ([, r]) => !this.currentLocation || r.branch === this.currentLocation
        )
        .filter(([, r]) => Number.isFinite(r.register_number)) // needed to prevent odd default behavior
        .map(([, r]) => ({
          value: r.register_number,
          label: this.$formatRegister(r),
          key: r.id
        }))
    },
    translations() {
      return {
        deposit: this.$t('common.data.financials.types.deposit.label'),
        expense: this.$t('common.data.financials.types.expense.label'),
        sale: this.$t('common.data.financials.types.sale.label'),
        sale_cancel: this.$t('common.data.financials.types.sale_cancel.label'),
        bank: this.$t('common.data.financials.secondaries.types.bank.label'),
        deposit_cancel: this.$t(
          'common.data.financials.secondaries.types.deposit_cancel.label'
        ),
        expense_cancel: this.$t(
          'common.data.financials.secondaries.types.expense_cancel.label'
        ),
        refund: this.$t(
          'common.data.financials.secondaries.types.refund.label'
        ),
        safe: this.$t('common.data.financials.secondaries.types.safe.label'),
        safes: this.$t('common.data.financials.secondaries.types.safes.label'),
        safe_deposit: this.$t(
          'common.data.financials.secondaries.types.safe_deposit.label'
        ),
        safe_deposit_cancel: this.$t(
          'common.data.financials.secondaries.types.safe_deposit_cancel.label'
        ),
        safe_expense: this.$t(
          'common.data.financials.secondaries.types.safe_expense.label'
        ),
        tip: this.$t('common.data.financials.secondaries.types.tip.label'),
        tip_expense: this.$t(
          'common.data.financials.secondaries.types.tip_expense.label'
        )
      }
    }
  },
  async mounted() {
    await this.fetchResources()
    this.$emitter.on('refresh-requested', () => {
      const computedPath = this.$makeFilteredPath(this.$route.path, {
        ...this.parsedQuery,
        branch: this.currentLocation
      })
      this.$router.push(computedPath)
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },

  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.transactions.title')
        })
      })
    },
    handleOpenReceipts(row) {
      this.currentRow.id = row.resourceId
      this.currentRow.receipts = row.receipts
      this.isModalOpen = true
    },
    closeReceiptViewer() {
      this.isModalOpen = false
    },
    handleOpenImages({ resourceId, transaction }) {
      this.currentRow.id = resourceId
      this.currentRow.transaction = transaction
      this.toggleOpen(!this.isImageViewerOpened)
    },
    closeImageViewer() {
      this.$modal.hide('tx-images')
    },
    txResource() {
      return th.analyticsHandlersV3({ timeout: 120000 }).analytics.reports
        .AnalyticsReportsTransactions
    },
    // NOTE: this is a perfomance optmisation in order not to parse in formatters, which seems to be costly in massive data scenarios.
    // The gist is: pre-digest strings, so the call stacks get thinner later. This mutates actual data inside the table
    async transformFetchedData(data) {
      await waitForData(() => this.resources.staff)
      return data.map((item) => {
        const staff = this.resources.staff.get(item.cashier_staff)
        if (staff) {
          item._staff_label = this.$formatStaff(staff)
        }
        // New types were added for DAS-1785
        if (!item.additional_type && !item.type) {
          item._type_label = '-'
        } else if (
          item.type === 'sale' &&
          !item.additional_type &&
          item.total < 0
        ) {
          item._type_label = this.$t(
            `common.data.financials.secondaries.types.refund.label`
          )
        } else if (
          item.type === 'expense' &&
          item.additional_type === 'expense'
        ) {
          item._type_label = this.$t(
            `common.data.financials.secondaries.types.expense.label`
          )
        } else if (
          item.type === 'expense_cancel' &&
          item.additional_type === 'expense'
        ) {
          item._type_label = this.$t(
            `common.data.financials.secondaries.types.expense_cancel.label`
          )
        } else if (
          item.type === 'expense' &&
          item.additional_type === 'deposit'
        ) {
          item._type_label = this.$t(
            `common.data.financials.secondaries.types.deposit.label`
          )
        } else if (
          item.type === 'expense_cancel' &&
          item.additional_type === 'deposit'
        ) {
          item._type_label = this.$t(
            `common.data.financials.secondaries.types.deposit_cancel.label`
          )
        } else if (
          item.type === 'expense' &&
          item.additional_type === 'safe_expense'
        ) {
          item._type_label = this.$t(
            `common.data.financials.secondaries.types.safe_deposit.label`
          )
        } else if (
          item.type === 'expense_cancel' &&
          item.additional_type === 'safe_expense'
        ) {
          item._type_label = this.$t(
            `common.data.financials.secondaries.types.safe_deposit_cancel.label`
          )
        } else {
          item._type_label = this.translations[
            item.additional_type || item.type
          ]
        }

        if (!item.date) {
          item._date_label = '-'
          item._date_local_label = '-'
        } else {
          item._date_label = this.$date.formatDateTimeWithTimezone(
            item.date,
            item.timezone
          )
          item._date_local_label = this.$date.formatDateTimeWithTimezone(
            item.date
          )
        }

        return item
      })
    },
    handleHeadersConfig(config) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: 'settings.headerFilters.transactions',
        value: config || {}
      })
    },
    handleSubmit(filters) {
      this.$ampli.eventWithBaseProps('reportsFiltersSearchButtonClick')
      // Adding branch always, as is part of the resourceQuery
      if (this.currentLocation) filters.branch = this.currentLocation
      const shouldOmitSwitchFilterItem = (key, value) =>
        key === 'unsigned' && [false, 'false'].includes(value)
          ? undefined
          : value

      const query = compose(
        parseQueries(shouldOmitSwitchFilterItem),
        makeQuery(this.$route.path)
      )(filters)

      this.$router.push(query)
    },
    async handleExport(query) {
      this.$ampli.eventWithBaseProps('reportsExportButtonClick')
      try {
        const {
          data
        } = await th
          .analyticsHandlersV3()
          .analytics.reports.AnalyticsReportsTransactions.export(query)

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.transactions.title',
            date: new Date(),
            action: {
              entity: 'analyticsHandlersV3',
              path: 'analytics.reports.AnalyticsReportsTransactions',
              handler: 'export',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.transactions.title')
          })
        })
      }
    },
    toggleOpen(isOpen) {
      this.isImageViewerOpened = isOpen
    },
    async fetchResources() {
      const { paymentOptions = [] } = await this.$resourceFetch(
        'paymentOptions'
      )
      this.paymentOptions = paymentOptions
    },
    mapOptions(list = [], dataKey = 'name') {
      return list
        .map((item) => ({
          value: item[dataKey],
          label: this.paymentTypesLabels[item[dataKey]] || item[dataKey]
        }))
        .filter(
          (v, i, a) =>
            a.findIndex((t) => ['value'].every((k) => t[k] === v[k])) === i
        ) // Filter out duplicates
    }
  }
}
</script>

<style scoped></style>
